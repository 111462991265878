const routes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: 'HomeLayout',
    componentPath: 'pages/Home',
    redirect: '/home',
    auth: false,
    childrens: [],
  },
  {
    component: 'AuthLayout',
    path: '/auth',
    auth: false,
    name: 'Auth',
    exact: false,
    redirect: '/auth/login',
    childrens: [
      {
        component: 'Login',
        path: '/login',
        componentPath: 'pages/Auth/login',
        name: 'Login',
        auth: false,
        exact: true,
      },
      {
        component: 'Signup',
        path: '/signup',
        componentPath: 'pages/Auth/signup',
        name: 'Signup',
        auth: false,
        exact: true,
      },
      {
        component: 'ForgotPwd',
        path: '/forgot_password',
        componentPath: 'pages/Auth/forgotPwd',
        name: 'ForgotPwd',
        auth: false,
        exact: true,
      },
      {
        component: 'ResetPwd',
        path: '/reset_password',
        componentPath: 'pages/Auth/resetPwd',
        name: 'ResetPwd',
        auth: false,
        exact: true,
      },
    ],
  },
  // {
  //   path: '/category',
  //   name: 'Category',
  //   exact: true,
  //   component: 'Category',
  //   componentPath: 'pages/Category',
  //   auth: false,
  //   childrens: [],
  // },
  {
    path: '/category/:id',
    name: 'Category',
    exact: true,
    component: 'Category',
    componentPath: 'pages/Category',
    auth: false,
    childrens: [],
  },
  // {
  //   path: "/product",
  //   name: "Product",
  //   exact: true,
  //   component: "Product",
  //   componentPath: "pages/Product",
  //   auth: false,
  //   childrens: [],
  // },
  {
    path: '/product/:id',
    name: 'Product',
    exact: true,
    component: 'Product',
    componentPath: 'pages/Product',
    auth: false,
    childrens: [],
  },
  {
    path: '/cart',
    name: 'Cart',
    exact: true,
    component: 'Cart',
    componentPath: 'pages/Cart',
    auth: false,
    childrens: [],
  },
  {
    path: '/orders',
    name: 'Orders',
    exact: true,
    component: 'Orders',
    componentPath: 'pages/Orders',
    auth: false,
    childrens: [],
  },
  {
    path: '/myOrder',
    name: 'MyOrder',
    exact: true,
    component: 'MyOrder',
    componentPath: 'pages/MyOrder',
    auth: false,
    childrens: [],
  },
  {
    path: '/kitchen-mate/dosa-chef/video',
    name: 'DemoVideo',
    exact: true,
    component: 'DemoVideo',
    componentPath: 'pages/kitchen-mate',
    auth: false,
    childrens: [],
  },
  {
    path: '/kitchen-mate/snack-chef/video',
    name: 'snackChef',
    exact: true,
    component: 'snackChef',
    componentPath: 'pages/kitchen-mate/snackChef',
    auth: false,
    childrens: [],
  },
  {
    path: '/kitchen-mate/snacks-chef/video',
    name: 'SnackChefs',
    exact: true,
    component: 'SnackChefs',
    componentPath: 'pages/kitchen-mate/SnackChefs',
    auth: false,
    childrens: [],
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    exact: true,
    component: 'ContactUs',
    componentPath: 'pages/ContactUs',
    auth: false,
    childrens: [],
  },
  {
    path: '/our-experience-centers',
    name: 'OurExperienceCenters',
    exact: true,
    component: 'OurExperienceCenters',
    componentPath: 'pages/OurExperienceCenters',
    auth: false,
    childrens: [],
  },
  {
    path: '/blogs',
    name: 'Blogs',
    exact: true,
    component: 'Blogs',
    componentPath: 'pages/Blogs',
    auth: false,
    childrens: [],
  },
  {
    path: '/blog',
    name: 'Blog',
    exact: true,
    component: 'Blog',
    componentPath: 'pages/BlogDetail',
    auth: false,
    childrens: [],
  },
  {
    path: '/customer_service',
    name: 'CustomerService',
    exact: true,
    component: 'CustomerService',
    componentPath: 'pages/CustomerService',
    auth: false,
    childrens: [],
  },
  {
    component: 'AuthLayout',
    componentPath: 'pages/Dummy/dummy',
    path: '/store',
    auth: false,
    name: 'Dummy',
    exact: false,
    childrens: [],
  },
  {
    component: 'AuthLayout',
    componentPath: 'pages/Dummy/static',
    path: '/static/:text',
    auth: false,
    name: 'Static',
    exact: false,
    childrens: [],
  },
]

export default routes
